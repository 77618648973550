import i18n from "i18next"
import { initReactI18next } from "react-i18next"

export const resources = {
  en: {
    translation: {
      common: {
        faqTitle: "Frequently Asked Questions",
        learnMore: "Learn More",
        openNow: "Open Now",
        open: "Open",
        closed: "Closed",
        getDirections: "Get Directions",
        directions: "Directions",
        weeklyAd: "Weekly Ad",
        language: "Language",
        apply: "Apply",
        add: "Add",
        home: "Home",
        unauthorized: "User is not Authorized! Please login.",
        tokenNotFound: "Authorization token not found.",
        somethingWentWrong: "Something went wrong",
        invalidCredentials: "Invalid Credentials",
        loadingContent: "Loading content",
        update: "Update",
        cancel: "Cancel",
        loremSmall: "Lorem ipsum",
        px: "px",
        results_zero: "No results found",
        results_one: "1 result",
        results: "{{count}} results",
        sortedBy: "Sorted by {{sortBy}}",
        sortBy: "Sort by",
        setAsMyStore: "Set as My Store",
        login: "Log In",
        logout: "Log Out",
        signUp: "Sign Up",
        selectStore: "Select a Store",
        bulletPoint: "•",
        features: "Features",
        chaseTheSavings: "Chase-The-Savings",
        gasRewards: "Gas Rewards",
        fandango: "Fandango",
      },
      weeklyAd: {
        selectStoreToSeeWeeklyAd: "Please select a store to see the Weekly Ad",
      },
      couponCard: {
        exp: "Exp. {{date}}",
        label: {
          name: "coupon card",
        },
        alt: {
          close: "Close",
          image: "Image",
          clip: "Clip",
        },
      },
      offers: {
        errors: {
          error: "Sorry, a error occurred",
        },
        loading: "Loading...",
        pageTitle: "Offers",
      },
      navBarMenu: {
        giftCards: "Gift Cards",
        back: "Back",
        cancel: "Cancel",
        closeMenu: "Close Menu",
        search: {
          placeholder: "Search products",
        },
        signIn: "Log In / Sign Up",
        coupons: "Coupons",
        shopNow: "Shop Now",
        shopNowAriaLabel: "Shop Now in a new tab",
        weeklyAd: "Weekly Ad",
        locations: "Locations",
        selectStore: "Select a Store",
        myRewards: "My Rewards",
        myCoupons: "My Coupons",
        myAccount: "My Account",
        faqs: "FAQ",
        about: "About us",
        contact: "Contact us",
        signOut: "Sign Out",
        cart: "Cart",
        navBarButtonAriaLabel: "Open and close side navigation menu",
        searchAriaLabel: "Search products",
        savemartRewards: "My Rewards",
        shoppingList: "Shopping List",
        seasonPicks: "Season Picks",
        accountSettings: "Account Settings",
        profileAriaLabel: "Open user profile",
        allDepartments: "Product Categories",
        backToAllDepartments: "Back to Product Categories",
        departments: "Products",
        products: "Products",
        madeToOrder: "Made to Order",
        maxxSavings: "MAXXsavings",
      },
      subNavBar: {
        myStore: "My Store:",
        selectStore: "Select a Store",
        noStoreSelected: "No Store Selected",
        shopNow: "Shop Now",
        seletStoreTitle: "Choose your store to get started.",
        location: "Locations",
        weeklyAds: "Weekly Ad",
        seasonPick: "Season Picks",
        savemartRewards: "My Rewards",
        recipes: "Recipes",
        nowHiring: "Careers",
      },
      bottomNavBar: {
        giftCards: "Gift Cards",
        takeSurvey: "Take a brief survey about today's visit.",
        helpUsImprove: "Help us improve",
        startSurvey: "Start Survey",
        callUs: "Call us Toll-Free <0>{{number}}</0>",
        monToFridayTime: "MON-FRI {{from}} – {{to}}",
        "sat&SundayTime": "SAT & SUN {{from}} – {{to}}",
        disclaimerTitle: "Disclaimer:",
        disclaimerText:
          "{{siteName}} strives to keep its information accurate and up to date. This information may be different than what you see when you visit a store. All  products and services are presented without warranty. When evaluating offers, please review the Terms and Conditions.",
        copyRights: "©2022 - {{year}}. {{siteName}}. All Rights Reserved.",
        subTitle: "Low prices every day!",
        appTitle: "DOWNLOAD OUR APP TODAY!",
        legal: "Legal",
        quickLinks: "QUICK LINKS",
        whoWeAre: "Who We Are",
        customerService: "Customer Service",
        privacyPolicy: "Privacy Policy",
        terms: "Terms of Use",
        RewardsFAQ: "Rewards Terms and Conditions",
        about: "About Us",
        contact: "Contact Us",
        findStore: "Find a Store",
        careers: "Careers",
        backToTop: "Back to Top",
        env: "env: {{env}}.",
        facebook: "Facebook",
        twitter: "Twitter",
        linkedin: "Linkedin",
        instagram: "Instagram",
        aboutUs: "About Us",
        policies: "Policies",
        shop: "Shop Now",
        weekly: "Weekly Ad",
        shopping: "Shopping List",
        location: "Locations",
        rewards: "Rewards",
        shoppingList: "Shopping List",
        accessibility: "Accessibility Statement",
        choices: "Ad Choices",
        privacyRights: "CA Privacy Rights",
        DoNotSellMyInfo: "Do Not Sell or Share My Personal Information",
        propositionWarning: "CA Proposition 65 Warnings",
        supplyChain: "Supply Chain Transparency",
        cookiesPolicy: "Cookies and Ads Policy",
        recallInformation: "Recall Information",
        companyInfo: "Company Info",
        community: "Community",
        news: "News",
        supplierPortal: "Supplier Portal",
        contactUs: "Contact Us",
        pressRelease: "Press Releases",
        quickLink: "Quick Links",
        consumerInformation: "Consumer Information",
        maxxSavings: "MAXXsavings",
        madeToOrder: "Made to Order",
        faq: "FAQ",
        recipes: "Recipes",
      },
      adsTest: {
        title: "Ads Test",
        message: "This is the ads test (carousel below)",
      },
      brandedLandingPage: {
        unhandledIndex: "Unhandled item at index: {{index}}",
      },
      flyers: {
        title: "Weekly Ad",
        placeholder: "placeholder for flyers",
      },
      home: {
        adsHere: "Ads Here!",
        newBrandsArrived: "New Brands Arrived to our Stores!",
        discoverworldofNestle: "Discover the world of Nestlé",
        takemostfrommilk: "Take the most out of milk",
        shopProducts: "Shop Products",
        hotAisleFeaturedCategories: "Hot Aisle / Featured Categories",
        chocolate: "Chocolate",
        coffee: "Coffee",
        shopNewAdditions: "Shop New Additions!",
        viewAll: "View All",
        newPromotion: "New Promotions from CPMG today!",
        title: "Home",
      },
      login: {
        title: "Log In",
        pageTitle: "Log In or Sign Up",
        foodmaxxTitle: "Log In or Sign Up",
        // pageSubtitle: "Please sign in or create an account",
        email: "Your Email",
        password: "Password",
        rememberMe: "Remember Me",
        submit: "Submit",
        login: "Log In",
        register: "Don't have an account?",
        forgotPassword: "Forgot Password?",
        signUp: "Sign Up",
        enterWith: "Or Enter With",
        google: "Google",
        facebook: "Facebook",
        twitter: "Twitter",
        emailError: "The account with such email doesn’t exist",
        passwordError: "Invalid Password",
      },
      register: {
        title: "Sign Up",
        pageTitle: "Log In or Sign Up",
        pageSubtitle: "All fields are required",
        first_name: "First Name",
        last_name: "Last Name",
        email: "Your Email",
        password: "Password",
        confirmPassword: "Confirm Password",
        subscribe:
          "I want to subscribe to the latest deals & savings via email",
        employee: "I am an Employee",
        register: "Sign Up",
        login: "Already have an account?",
        phone: "Your Phone Number",
        // birthday: "Birthday",
        zipcode: "Your Zip Code",
        iAgree: "By clicking the Sign Up button I agree to the following",
        privacyPolicy: "Privacy Policy",
        and: "and",
        termsConditions: "Terms & Conditions",
        birthday: "Birthday",
        subscribeViaEmail:
          "I want to subscribe to the latest deals & savings via email",
        rememberMe: "Remember Me",
        passwordMust:
          "Password must be between 8 and 12 characters and must contain at least 1 character from each of the following:",
        letters: "Letters (a-z) (A-Z)",
        numbers: "Numbers (0-9)",
        specialCharacters: "Special characters (@, #, $, !, %, &, etc.)",
        eightChar: "At least 8 characters",
        passwordUpper: "1 uppercase & 1 lowercase letter",
        number: "1 number",
        atLeastChar:
          "At least 1 of these characters @$!%*?& (no other characters)",
        employeeId: "Employee ID",
        birthMonth: "Birth Month",
        birthDay: "Birth Day",
        selectMonth: "Select month",
        selectDay: "Select day",
        lastStep: "One last step...",
        lastStepMsg:
          "Please select your preferred store to see store-specific ads & pricing and receive local offers.",
        selectStore: "Select a Store",
        action: "Action",
        or: "or",
        cancelSignUp: "Cancel Sign Up",
        continue: "Continue",
        accountCreated: "Account has been created",
        registrationSuccess:
          "We have successfully created your account. You can start saving now!",
        startSaving: "Start Saving",
        requiredField: "Required field",
        cancelSignUpQue: "Cancel Sign Up?",
        continueSignUp: "Continue Sign Up",
        cancelSignUpMsg:
          "Your account hasn’t been created yet. If you cancel Sign Up now, your account won’t be created and you will lose all entered data.",
        yesCancelSignUp: "Yes, Cancel Sign Up",
        isMobile: "This is a Mobile Phone",
        accountNotCreated: "Account cannot be created",
        reviewData: "Review My Data",
        birthYear: "Birth Year",
        selectYear: "Select year",
      },
      forgotPassword: {
        setPassword: "Set Password",
        sendCode: "Send Code",
        title: "Forgot Your Password",
        pageTitle: "My Savemart",
        pageSubtitle: "Reset your Password to login",
        email: "Your Email",
        getCode: "Get Code",
        linkSent: "A link has been sent to your email",
        backToLogin: "Back to Log In page",
        subTitle:
          "Please enter your email address below and we will send you a code to reset your password.",
        passSubTitle:
          "Please enter the code we sent you and a new password below.",
        setNewPassword: "Set New Password",
        receivedCode: "Didn’t receive a code?",
        checkYourEmailIfCodeNotReceived:
          "Didn’t receive a code? If you didn't receive a code, please verify you signed up with {{email}} and request a new code again.",
        getNewCode: "Get a New Code",
        codeFromEmail: "Code from email",
        newPassword: "New Password",
        forgotPass: "Password Changed",
        passSuccessMsg: "You have successfully changed your password.",
        done: "Done",
        changePassword: "Change Password",
        errorMessage:
          "Invalid email. This email is not registered yet. Please try again with registered email.",
      },
      profile: {
        title: "Account",
        accountOverview: "Account Overview",
        myOrders: "My Orders",
        mySubscriptions: "My Subscriptions",
        myRewards: "My Rewards",
        myCoupons: "My Coupons",
        myItems: "My Items",
        myFavoriteProducts: "My Favorite Products",
        myStore: "My Store",
        storeDetails: "Store Details",
        chooseAnotherStore: "Choose Another Store",
        memberSince: "Member since October 2021",
        myProfile: "My Profile",
        editProfile: "Edit Account",
        hey: "Hey, {{firstName}}!",
        info: "Info",
        logout: "Logout",
        MyProfile: "My Profile",
        signOut: "Sign Out",
        myAccountCardDetails: "Manage your account info",
        storeDetailsButtonLabel: "View Store Details",
        myRewardsButtonLabel: "View My Rewards",
        myCouponsButtonLabel: "View My Coupons",
        manageAccountButtonLabel: "Manage Account",
        points: "{{points}} Points",
        myCouponsDetails: "{{clippedCouponsCount}} Clipped",
        accountSettings: "Account Settings",
        greetingsText: "Hey",
        joiningDateText: "Member Since October 2021",
        showMyBarcode: "Show My Barcode",
        closeBarcode: "Barcode Close Button",
        rewardPointBalance: "Reward Points Balance",
        availableCashback: "Available Cashback",
        cashOut: "Cash Out",
        doubleDigitChk: "$36",
        tripleDigitChk: "145",
        expiresOn: "Expires On",
        expiresSoon: "Expires Soon",
        expiringDate: "Jan 20",
        coupons: {
          pageTitle: "My Coupons",
          allCoupons: "All Coupons",
          rebateCoupons: "({{count}}) Cashback Coupons",
          coupons: "({{count}}) Coupons",
          clipped: "Clipped ({{count}})",
          redeemed: "Redeemed ({{count}})",
          noCouponsClipped: "No Coupons Clipped",
          noCouponHistory: "No Coupons History",
        },
        edit: {
          pageTitle: "Edit Account",
          pageSubtitle: "Edit your Account information",
          title: "Edit Account",
          firstName: "First Name",
          lastName: "Last Name",
          phoneNumber: "Phone Number",
          zipCode: "Zip Code",
          save: "Save",
          cancel: "Cancel",
          email: "Your Email",
        },
        details: {
          pageTitle: "Profile Details",
          personalInfo: "Personal Info",
          accountAccess: "Account Access",
          notification: "Notifications",
          edit: "Edit",
          close: "Close",
          editModalTitle: "Update Your Details",
          fullName: "Full Name",
          email: "Email",
          phoneNumber: "Phone Number",
          zipCode: "Zip Code",
          verified: "Verified",
          notVerified: "Not Verified",
          smsOptIn: "SMS Opt-In",
          emailOptIn: "Weekly email news and deals",
          changePassword: "Change Password",
          password: "Password",
          allFieldsRequired: "All fields are required",
          subscriptions: "Subscriptions",
          cashbackEmail: "Cashback Email",
          paypal: "PayPal",
          cashBackTitle: "Update Your Cashback Email",
          emailLabel: "Your Cashback Email",
          cashbackErrors: {
            email: "Please enter valid email.",
          },
        },
        rewards: {
          pageTitle: "My Rewards",
          showMyBarcode: "Show My Barcode",
          closeMyBarcode: "Close My Barcode",
          saveSmartRewards: "Save Mart Rewards",
          luckyRewards: "Lucky Rewards",
          loyaltyId: "Loyalty ID: {{id}}",
          pointsBalance: "Points Balance",
          expiresOn: "Expires on: ",
          description:
            "Welcome to Save Mart Rewards! Savings You Want. Rewards You'll Love. Convert your points into free product or dollars off your next shopping trip.",
          luckydescription:
            "Welcome to Lucky Rewards! Savings You Want. Rewards You'll Love. Convert your points into free product or dollars off your next shopping trip.",
          availableRewards: "Available Rewards ({{count}})",
          rewardHistory: "Reward History",
          noRewards: "No rewards available",
          noHistory: "No rewards history",
          close: "Close",
          barcodeText: "Use this barcode to scan your Rewards card in store.",
          closeBarcode: "Barcode Close Button",
          myAddedRewards: "My Added Rewards ({{count}})",
        },
      },
      stores: {
        storeSeleted: "{{name}} store selected",
        select_another_store: "Select another store",
        choose_a_store: "Choose a store",
        locateNearbyStore: "Locate a nearby store",
        select_a_store: "Select a store",
        closed_permanently: "Location Permanently Closing Soon",
        location_coming_soon: "Location Permanently Closing Soon",
        open_now: "Open Now",
        pageTitle: "Find A Store | {{siteName}}",
        title: "Locations",
        setZipcode: "Set Zipcode",
        foundStores: "Found {{count}} stores",
        todayHours: "Today's Hours: {{hours}}",
        seeAll: "See All",
        nearbyStores: "Nearby Stores",
        setAsMyStore: "Set As My Store",
        myStore: "My Store",
        noStoresFound:
          "Sorry, there are no locations matching your search. Please modify your search and try again or browse our directory.",
        aboutThisStore: "About This Store",
        aboutStore: "About {{name}} store",
        browseDepartments: "Browse Product Categories",
        jobOpening: "Job Openings in this Store",
        applyOnline: "Apply online",
        emptyDescription: "-",
        services: "Services",
        closesAt: "Closes at",
        store_phone: "Store phone",
        payments: "Payment Options",
        list: "List",
        map: "Map",
        departments: "Product Categories",
        finishSignUp: "Finish Sign Up",
        storeSearchInputPlaceholder: "Enter zip code, city, or state",
        noOptionsText: "Please input a zipcode, city, or state",
        noOptionsTextWhenIncorrectInput:
          "Please input correct zipcode, city, or state",
        errors: {
          tryAgain: "Something went wrong! Please try again...",
        },
      },
      products: {
        loading: "Loading...",
        linkToStoreSearch: "Select a store for pricing",
        productDetails: "Product Details",
        eligibleFor: "Eligible for",
        qualifyingCoupons: "Qualifying Coupons",
        mixAndMatch: "Mix & Match",
        sale: "Sale",
        details: "Product Details",
        sortDimensions: {
          featured: "Featured",
          alphaAsc: "A-Z",
          alphaDesc: "Z-A",
          priceAsc: "Price: Low to High",
          priceDesc: "Price: High to Low",
        },
        modal: {
          StoreNotSelected: "Select a Store for Pricing",
          addToShoppingList: "Add to shopping list",
          removeFromShoppingList: "Remove from shopping list",
        },
      },
      productSearch: {
        filterAndSort: "Filter & Sort",
        seeMore: "See More",
        resultsType: "Results Type",
        lastUpdatedOn: "Last Updated on",
        resultsFor: "Search Results for '{{searchTerm}}'",
        clearAll: "Clear All",
        searchResults: "Search Results",
        clear: "Clear",
        seeResults: "See Results",
        noResultsFound: "No results found for '{{searchTerm}}'",
        emptySearchString: "Please enter a search term",
        refine: "Refine",
        selectAStore: "Select a store",
        filter: "Filter",
        weRecommendYou: "We recommend you:",
        checkTheSpelling: "Check the spelling of the word.",
        tryGenericWords: "Try using more generic words or fewer words.",
        browseTheCategory: "Browse the categories to find a similar product.",
        appliedFilters: "Applied Filters",
        filterBy: "Filter by",
      },
      categories: {
        title: "Product Categories",
        itemListInfo: {
          browseBySubcategory: "Browse by Subcategory",
          products: "Products",
          filter: "Filter",
          productsPerPage: "Products per page",
        },
        selectStore: "Select a Store",
      },
      notFoundCard: {
        description: "{{id}} could not be found",
      },
      storeInfo: {
        title: "Store Info",
      },
      storeHours: {
        title: "Store Hours",
      },
      brands: {
        title: "Brand",
      },
      help: {
        title: "Help",
      },
      theme: {
        sprys: "Sprys Theme",
      },
      selectStore: "Select Store",
      pagination: {
        countText: "{{start}}-{{end}} of {{total}}",
      },
      coupons: {
        title: "Coupons",
        headerText: "Coupons",
        activateText: "Active",
        claimedText: "Claimed",
        redeemedText: "Redeemed",
        clippedText: "Clipped",
        expiredText: "Expired",
        placeholder: "Coupons",
        rebateDetails: "Cashback Coupon Details",
        details: "Coupon Details",
        expiry: "Expires {{str}}",
        clip: "Clip Coupon",
        paypalButtonText: "Add your PayPal account to get cashback",
        terms: "Terms & Conditions",
        byCategories: "By Categories",
        claimError: "Error claiming coupon",
        claimSuccess: "Coupon claimed successfully",
        rebates: {
          rebateCashback: "Rebate Cashback",
          cash: "${{ cash }}",
          rebateCount: "({{count}}) Cashback Coupons",
          addPaypalEmail: "Add PayPal account",
          readyToCashOut: "Ready to cash out",
          expires: "Expires",
          expireDays: "In {{ days }} days",
          nothingToExpire: "Nothing to expire",
          notificationToAddEmail: "Add your PayPal account to get cashback",
          cashOutBtn: "Cash Out",
          showDetails: "Show Details",
          hideDetails: "Hide Details",
          cashbackExpirationDetails: "Cashback Expiration Details",
          cashbackSendMsg: "Cashback has been sent to {{ email }} via PayPal",
          cashbackErrorMsg:
            "We were unable to cashout your funds. Please try again later.",
          addPaypalEmailMsg: "Add your PayPal account to get cashback",
          yourPaypalEmail: "Your PayPal Email",
          paypalError: "Please enter a valid email.",
          close: "Close",
          add: "Add",
          later: "Later",
        },
        sortDimensions: {
          expiresSoon: "Expires Soon",
          mostClipped: "Most Popular",
          mostSavings: "Most Saving",
          newestFirst: "Newest",
          topDeals: "Featured",
        },
        tabLabels: {
          availableCoupons: "Available Coupons",
          clippedCoupons: "Clipped Coupons",
          couponsHistory: "Coupons History",
        },
        messageForLogoutUserFirstPart:
          "Rewards club members can see clipped and redeemed coupons, and have access to extra savings - ",
        logIn: "Log In",
        or: "or",
        signUp: "Sign Up",
        messageForLogoutUserSecondPart: "to save more!",
        rebateDescriptionTitle: "Rebate Description",
        rebateTermsTitle: "How Rebate Works",
        rebateTermsDescription:
          "Clip a rebate in app before your purchase. Identify at checkout using your member ID. Cash your balance to PayPal.",
        topBarText: {
          new: "New",
          featured: "Featured",
          expireSoon: "Expires Soon",
        },
      },
      loginRequestModal: {
        title: "Login Required",
        message: "You need to login to proceed",
      },
      rewardsCard: {
        points: "{{points}} Points",
        pointsAway: "{{points}} Points Away",
        redeem: "Add reward",
        exp: "Exp",
      },
      rewards: {
        rewardsAddedSuccessfully: "Rewards added successfully",
        title: "Rewards",
        rewardCount: "{{count}} REWARDS",
        couponCount: "{{count}} COUPONS",
        loyaltyId: "Loyalty ID: {{id}}",
        browse: "Browse Rewards",
        coupons: "Coupons",
        headerText: "Rewards",
        activateText: "Active",
        claimedText: "Claimed",
        redeemedText: "Redeemed",
        placeholder: "Coupons",
        details: "Rewards Details",
        myRewards: "My Rewards",
        shop: "Shop",
        shopDescription:
          "The more you shop with Save Mart, the greater the rewards!",
        luckyshopDescription:
          "The more you shop with Lucky Supermarkets, the greater the rewards!",
        shoppingCart: "Shopping Cart",
        earn: "Earn",
        earnDescription:
          "Share your phone number at checkout to earn points when you shop.",
        dollarCoins: "Dollar Coins",
        getRewarded: "Get Rewarded!",
        getRewardedDescription: "Redeem your points for rewards and savings.",
        starBadge: "Star Badge",
        loginOrCreateAccount: "Log In or Sign Up to see your rewards.",
      },
      couponsInfo: {
        title: "Coupon Details",
        headerText: "{{name}} Coupon Details",
        works: "How it Works",
        terms: "Terms & Conditions",
      },
      couponsRefine: {
        refine: "Refine",
      },
      couponsMessage: {
        notLoggedIn:
          "Rewards club members can see clipped and redeemed coupons, and have access to extra savings - <0>Log In</0> or <1>Sign Up</1> to save more! ",
        loggedOut: "You have been logged out",
        notLoggedInFoodmaxx:
          "<0>Log In</0> or <1>Sign Up</1> to save more with coupons and digital exclusive offers!",
      },
      rewardsInfo: {
        title: "Reward Details",
        headerText: "{{name}} Reward Details",
        works: "How it Works",
        terms: "Terms & Conditions",
      },
      app: {
        couponDialogText: "To use this coupon, you must download the app.",
        openText: "Open App",
        mainAriaLabel: "Main content area",
      },
      configMissing: {
        title: "Configurations Missing",
        missingText: "Following keys are missing in environment variables:",
        keys: "Add these keys in variables for proper functionality of app",
      },
      contact: {
        title: "Contact us",
        subTitle: "We are here to help you.",
        emailUsDirectly:
          "If you received an error with your submission, please email us directly at",
        callUs: "Call us",
        customerRelations: "Customer Relations",
        corporateOffice: "Corporate Office",
        successMessage:
          "We have received your message and will respond next business day.",
      },
      flyersList: {
        noAds: "No ads or books this week.",
      },
      setStore: {
        set: "Set Your Store",
        select: "Select a store to see your weekly ad!",
        find: "Find a store",
      },
      adChoices: {
        title: "Ad Choices",
        pageTitle: "Ad-Choices pageTitle",
        searchKeyword: "Ad-Choices searchKeyword",
        location: "Ad-Choices Location",
        storeLevel: "Ad-Choices Store Level",
        corporateLevel: " Ad-Choice sCorporate Level",
        warehouseLevel: "Ad-Choices Warehouse Level",
      },
      CaliforniaPrivacyRights: {
        title: "California Privacy Rights",
        pageTitle: "California-Privacy-Rights pageTitle",
        searchKeyword: "California-Privacy-Rights searchKeyword",
        location: "California-Privacy-Rights Location",
        storeLevel: "California-Privacy-Rights Store Level",
        corporateLevel: " California-Privacy-Rights sCorporate Level",
        warehouseLevel: "California-Privacy-Rights Warehouse Level",
      },
      ConsumerRightsRequest: {
        title: "Consumer Rights request",
        allFieldsReq: "All fields are required",
        requestorType: "Requestor Type",
        typeOfRequest: "Type of Request",
        consumerInfo: "Consumer Information",
        consumerInfoDescription:
          " Please enter information regarding the person on whose behalf request is being made below so their identity can be verified.",
        relationship: "Relationship with Us",
      },
      CaliforniasProposition65Warnings: {
        title: "Prop 65 Warning",
        pageTitle: "prop-65-warning pageTitle",
        searchKeyword: "prop-65-warning searchKeyword",
        location: "prop-65-warning Location",
        storeLevel: "prop-65-warning Store Level",
        corporateLevel: "prop-65-warning sCorporate Level",
        warehouseLevel: "prop-65-warning Warehouse Level",
      },
      supplyChainTransparency: {
        title: "Supply Chain Transparency",
        pageTitle: "supply-chain-transparency pageTitle",
        searchKeyword: "supply-chain-transparency searchKeyword",
        location: "supply-chain-transparency Location",
        storeLevel: "supply-chain-transparency Store Level",
        corporateLevel: "supply-chain-transparency sCorporate Level",
        warehouseLevel: "supply-chain-transparency Warehouse Level",
      },
      saveMartConditions: {
        title: "Save Smart Rewards Program Terms and Conditions",
      },
      privacyPolicy: {
        title: "Privacy Policy",
        pageTitle: "privacy-policy pageTitle",
        searchKeyword: "privacy-policy searchKeyword",
        location: "privacy-policy Location",
        storeLevel: "privacy-policy Store Level",
        corporateLevel: "privacy-policy sCorporate Level",
        warehouseLevel: "privacy-policy Warehouse Level",
      },
      termsOfUse: {
        title: "terms-and-conditions",
        pageTitle: "terms-and-conditions pageTitle",
        searchKeyword: "terms-and-conditions searchKeyword",
        location: "terms-and-conditions Location",
        storeLevel: "terms-and-conditions Store Level",
        corporateLevel: "terms-and-conditions sCorporate Level",
        warehouseLevel: "terms-and-conditions Warehouse Level",
      },
      madeToOrder: {
        title: "made-to-order",
        pageTitle: "made-to-order pageTitle",
        searchKeyword: "made-to-order searchKeyword",
        location: "made-to-order Location",
        storeLevel: "made-to-order Store Level",
        corporateLevel: "made-to-order sCorporate Level",
        warehouseLevel: "made-to-order Warehouse Level",
      },
      cookiesAndAdsPolicy: {
        title: "Cookies and Ads Policy",
        pageTitle: "cookies-and-ads-policy pageTitle",
        searchKeyword: "cookies-and-ads-policy searchKeyword",
        location: "cookies-and-ads-policy Location",
        storeLevel: "cookies-and-ads-policy Store Level",
        corporateLevel: "cookies-and-ads-policy sCorporate Level",
        warehouseLevel: "cookies-and-ads-policy Warehouse Level",
      },
      companyInfo: {
        title: "company-info",
        pageTitle: "company-info pageTitle",
        searchKeyword: "company-info searchKeyword",
        location: "company-info Location",
        storeLevel: "company-info Store Level",
        corporateLevel: "company-info sCorporate Level",
        warehouseLevel: "company-info Warehouse Level",
      },
      recallIormation: {
        title: "Recall Information",
        pageTitle: "recall-information pageTitle",
        searchKeyword: "recall-information searchKeyword",
        location: "recall-information Location",
        storeLevel: "recall-information Store Level",
        corporateLevel: "recall-information sCorporate Level",
        warehouseLevel: "recall-information Warehouse Level",
      },
      tagRecalls: {
        title: "tag-recalls",
        pageTitle: "tag-recalls pageTitle",
        searchKeyword: "tag-recalls searchKeyword",
        location: "tag-recalls Location",
        storeLevel: "tag-recalls Store Level",
        corporateLevel: "tag-recalls sCorporate Level",
        warehouseLevel: "tag-recalls Warehouse Level",
      },
      community: {
        title: "Community",
        pageTitle: "community pageTitle",
        searchKeyword: "community searchKeyword",
        location: "community Location",
        storeLevel: "community Store Level",
        corporateLevel: "community sCorporate Level",
        warehouseLevel: "community Warehouse Level",
        communityEvent: "Community Event",
        requestDonation: "Request Donation",
        careFoundation: "Care Foundation",
        storeTour: "Store Tour",
      },
      shopNow: {
        title: "Shop Now",
        faq: "FAQ",
      },
      careers: {
        title: "Careers",
        pageTitle: "Careers pageTitle",
        searchKeyword: "Careers searchKeyword",
        location: "Careers Location",
        storeLevel: "Careers Store Level",
        corporateLevel: "Careers sCorporate Level",
        warehouseLevel: "Careers Warehouse Level",
      },
      aboutUs: {
        title: "about-us",
        pageTitle: "about-us pageTitle",
        searchKeyword: "about-us searchKeyword",
        location: "about-us Location",
        storeLevel: "about-us Store Level",
        corporateLevel: "about-us sCorporate Level",
        warehouseLevel: "about-us Warehouse Level",
      },
      homeDelivery: {
        title: "home-delivery",
        pageTitle: "home-delivery pageTitle",
        searchKeyword: "home-delivery searchKeyword",
        location: "home-delivery Location",
        storeLevel: "home-delivery Store Level",
        corporateLevel: "home-delivery sCorporate Level",
        warehouseLevel: "home-delivery Warehouse Level",
      },
      jobOpeningInThisStore: {
        title: "job-opening-in-this-store",
        pageTitle: "job-opening-in-this-store pageTitle",
        searchKeyword: "job-opening-in-this-store searchKeyword",
        location: "job-opening-in-this-store Location",
        storeLevel: "job-opening-in-this-store Store Level",
        corporateLevel: "job-opening-in-this-store sCorporate Level",
        warehouseLevel: "job-opening-in-this-store Warehouse Level",
      },
      contactUs: {
        title: "Contact Us",
        pageTitle: "contact-us pageTitle",
        sendUs: "Send us a message",
        allFields: "All fields are required",
        searchKeyword: "contact-us searchKeyword",
        location: "contact-us Location",
        storeLevel: "contact-us Store Level",
        corporateLevel: "contact-us sCorporate Level",
        warehouseLevel: "contact-us Warehouse Level",
        first_name: "First Name",
        last_name: "Last Name",
        email: "Email",
        send: "Send",
        phone: "Phone",
        feedback: "Feedback",
        storeLocation: "Store Location",
        productIssues: "Product Issues",
        provideAdditional: "Provide additional detail (optional)?",
        imNotRobot: "I am not a robot Recapcha",
        messageSent: "Your message has been sent",
        done: "Done",
      },
      storeTour: {
        getStarted: "Fill out this form to get started!",
        allFields: "All fields are required",
        first_name: "First Name",
        last_name: "Last Name",
        email: "Your Email",
        phone: "Your Phone Number",
        feedback: "Feedback",
        storeLocation: "Store Location",
        productIssues: "Product Issues",
        nameOfOrganization: "Name of organization",
        grade: "Grade",
        numberOfStudents: "Number of students",
        month: "Month",
        year: "Year",
        day: "Day",
        send: "Submit",
        hour: "Hour",
        minute: "Minute",
        preferredDate: "Preferred Date",
        preferredTime: "Preferred Time",
        am: "AM",
        pm: "PM",
      },
      appSetup: {
        siteName: "Site Name",
        description: "Site Description",
        title: "Site Title",
        tagline: "Site Tagline",
        apiServerBaseUrl: "API Server Base URL",
        apiClientBaseUrl: "API Client Base URL",
        chainId: "Chain ID",
        siteLogoDark: "Site Logo (for dark mode)",
        siteLogoLight: "Site Logo (for light mode)",
        taxonomyId: "Taxonomy ID",
        catalogRootName: "Catalog Root Name",
        adsId: "Ads Network ID",
        adsSiteId: "Ads Site ID",
        siteDomain: "Site Domain",
        mapsKey: "Google Maps Key",
        playStoreUrl: "Google Play Store App URL",
        appStoreUrl: "Apple Store App URL",
        rewardImage: "Reward Image URL",
        ifApplicable: "If Applicable",
        successRebuild: "Rebuild the project to apply environment variables!",
        successReload:
          "Setup mode is already completed! Please Rebuild project if changes are not applied",
        error: "Something went wrong!\nError: {{message}}",
        siteSec: "Site Configuration Section",
        storeSec: "Store Section",
        taxonomySec: "Taxonomy Section",
        adsSec: "Ads Section",
        appStoreSec: "App Store Section",
        rewardsSec: "Rewards Section",
        setupAppTheme: "To setup app theme please click ",
        here: "here",
      },
      themeSetup: {
        head: "App Theming",
        placeholder: "App theme settings",
        fontSettings: "Font Family setting",
        colorSettings: "Color Settings",
        editColor: "Edit Color",
        typographySettings: "Typography Settings",
        updateFontSize: "Update font size",
        updateFontWeight: "Update font Weight (boldness)",
        updateLineHeight: "Update line height for text",
        updateColor: "Update font color",
        successSave: "App theme saved successfully!",
        errorSave: "App theme Saving error!",
        typographyDialogTitle: "Change settings for {{message}}",
        preview: "Preview",
      },
      notFoundPage: {
        mainText: "Oops... That page doesn’t exist!",
        sorryMsg: "Sorry, the page you were looking for could not be found...",
        contactMsgFirst: "If you have a minute, please ",
        contactMsg: "Contact Us",
        contactMsgLast:
          " and tell us how you got on this page so that we can fix this error.",
        directionMsg: "Or you can go back to home page.",
        btnText: "Back to Home",
      },
      recipes: {
        title: "Recipes",
        mainText: "Hi there, recipes are coming soon!",
        laterMsg: "Please come back again later...",
      },
      peakSeasonPick: {
        title: "Peak Season Picks",
      },
      accessibility: {
        title: "Accessiblity Statement",
      },
      maxxCopons: {
        title: "MaxxCoupons-faq",
        pageTitle: "MaxxCoupons-faq pageTitle",
        searchKeyword: "MaxxCoupons-faq searchKeyword",
        location: "MaxxCoupons-faq Location",
        storeLevel: "MaxxCoupons-faq Store Level",
        corporateLevel: "MaxxCoupons-faq sCorporate Level",
        warehouseLevel: "MaxxCoupons-faq Warehouse Level",
      },
      metadata: {
        recipes: "Recipes",
        recipe: "Recipe: {{title}}",
        recipeDescription: "Recipe decription for {{title}}",
        recipesDescription: "Recipes",
        storedetails: "Store Detail",
        caresFountaion: "The Save Mart Companies CARES Foundation",
        requestDonation: "Request Donation",
        californiaPrivacyRightsDescription:
          "The California Shine the Light law (Cal. Civ. Code § 1798.83) permits residents of California to request certain details about how their information is shared with third parties for the third-parties’ direct marketing purposes.",
        californiaPrivacyRightsKeywords:
          "California Consumer Privacy Act (“CCPA”), The California Shine the Light law",
        californiaPrivacyRights: "California Privacy Rights",
        rewardLogin: "Rewards Login",
        homePageTitle: "Home",
        homePageDescription: "Home page of the site",
        homePageKeywords: "Home page, Landing page",
        storesTitle: "Stores Locations",
        storesDescription:
          "Looking for the closest store? Let us help! We can find a store near you along with helpful info such as directions, hours, services, and more!",
        storesKeywords:
          "Find a store near you. Get directions, phone numbers, store hours, and more!",
        categoriesTitle: "Product Categories",
        categoriesDescription: "Categories of Products",
        categoriesKeywords: "Categories, Products",
        accountTitle: "Account",
        accountSettings: "Account Settings",
        accountDescription: "Account Details",
        accountKeywords: "Account, Profile",
        couponsTitle: "Coupons",
        couponsDescription: "Available coupons",
        couponsKeywords: "Coupons",
        flyersTitle: "flyers",
        flyersDescription: "flyers",
        flyersKeywords: "flyers",
        rewardsTitle: "Rewards",
        rewardsDescription: "Rewards page",
        rewardsKeywords: "Rewards",
        myRewardsTitle: "My Rewards",
        myRewardsDescription: "My Rewards page",
        myRewardsKeywords: "My Rewards",
        loginTitle: "Log In",
        loginDescriptionSavemart:
          "Log in to access your Save Mart Rewards, digital coupons, and exclusive offers. New here? Sign up today to enjoy CASH BACK rewards for free groceries, digital coupons, free items, and early bird offers.",
        loginDescriptionLucky:
          "Log in to access your Lucky Rewards, digital coupons, and exclusive offers. New here? Sign up today to enjoy CASH BACK rewards for free groceries, digital coupons, free items, and early bird offers.",
        loginDescriptionFoodMaxx:
          "Log in to access your digital coupons and exclusive MAXXsavings offers. New here? Sign up today to unlock a new level of savings with digital coupons, free items, and early bird offers on fresh groceries.",
        loginKeywords: "Log in to access your Save Mart Rewards, Save Mart",
        registerTitle: "Register",
        registerDescription: "Register or create an account",
        registerKeywords: "Register, Sign Up, Create an account",
        consumerInfoTitle: "Consumer Information",
        consumerInfoDescription:
          "Learn about how to contact us regarding donations, gift cards, and other inquiries",
        consumerInfoKeywords:
          "Cart Retrieval, Soliciting Outside of Store, Gift Cards, Donation Requests",
        contactUsTitle: "Contact Us",
        contactUsDescription:
          "Use this form to contact us about anything from products to people. We value your feedback.",
        contactUsKeywords: "Help-line, Address",
        FAQsTitle: "FAQ",
        FAQsDescription:
          "Have a question? View the commonly asked questions about Rewards, Accounts, Login, Passwords, and Discounts.",
        FAQsKeywords: "Questions, Answers",
        AdChoicesTitle: "Ad Choices",
        AdChoicesDescription:
          "View the policies regarding how the company uses data to provide an optimal experience.",
        AdChoicesKeywords: "Advertising, Interests",
        CAprivacyRightsTitle: "CA Privacy Rights",
        CAprivacyRightsDescription:
          "CCPA, CPRA and other privacy related content for Californians.",
        CAprivacyRightsKeywords: "California Privacy Rights, Do Not Track",
        CAproposition65Title: "California’s Proposition 65 Warnings",
        CAproposition65Description:
          "Learn about the warnings that exist with some of the products we sell.",
        CAproposition65Keywords: "California’s Proposition 65 Warnings",
        supplyChainTitle: "Supply Chain Transparency",
        supplyChainDescription: "Supply Chain Transparency",
        supplyChainKeywords: "Supply Chain Transparency",
        cookiesAdPolicyTitle: "Cookies and Ads Policy",
        cookiesAdPolicyDescription:
          "This page contains our cookies and ads policy.",
        cookiesAdPolicyKeywords: "Cookies and Ads Policy",
        accessibilityTitle: "Accessiblity Statement",
        accessibilityDescription:
          "We are committed to providing a website accessible to all people.",
        accessibilityKeywords: "Accessiblity Statement",
        privacyPolicyTitle: "Privacy Policy",
        privacyPolicyDescription:
          "This page contains all the privacy related content we want you to be aware of.",
        privacyPolicyKeywords: "Privacy Policy",
        termsOfUseTitle: "Terms of Use",
        termsOfUseDescription: "These are the Terms of Use for the website.",
        termsOfUseKeywords: "Terms of Use",
        recallInfoTitle: "Recall Information",
        recallInfoDescription:
          "Check back here for items that have been recalled.",
        recallInfoKeywords: "Recall Information",
        communityTitle: "Community",
        communityDescription:
          "See how The Save Mart Companies engages with the community through donations and volunteering.",
        communityKeywords: "Community",
        careersAt: `Careers at {{brand}}`,
        careersTitle: "Careers",
        careersDescription: `Find your next career with {{brand}}. View all the current openings starting here.`,
        careersKeywords: "Careers",
        fandangoTitle: "Fandango",
        fandangoDescription: "Fandango",
        fandangoKeywords: "Fandango",
        gasRewardsTitle: "Gas Rewards",
        gasRewardsDescription: "Gas Rewards",
        gasRewardsKeywords: "Gas Rewards",
        storeToursTitle: "Store Tours",
        storeToursDescription: "Store Tours",
        storeToursKeywords: "Store Tours",
        maxxSavingsTitle: "Foodmaxx savings",
        maxxSavingsDescription: "Foodmaxx savings",
        maxxSavingsKeywords: "Foodmaxx savings",
        maxxCouponsTitle: "FoodMaxx Coupons FAQ",
        maxxCouponsDescription: "FoodMaxx Coupons FAQ",
        maxxCouponsKeywords: "FoodMaxx Coupons FAQ",
        RewardsFAQTitle: "Terms and conditions",
        RewardsTermsAndConditopns: "Rewards Terms and conditions",
        RewardsFAQDescription: "Terms and conditions",
        RewardsFAQKeywords: "Terms and conditions",
        ConsumerRightsRequestTitle: "Consumer Rights Request",
        ConsumerRightsRequestDescription: "Consumer Rights Request",
        ConsumerRightsRequestKeywords: "Consumer Rights Request",
        editAccount: "Edit Account",
        personalInfo: "Personal Info",
        myCoupons: "My Coupons",
        couponsCategory: "Coupon Categories",
        privacyPolicy: "Privacy Policy",
        supplyChainTransparency: "Supply Chain Transparency",
        supplyChainTransparencyDescription:
          "This is our commitment to the same high standards of ethical and legal conduct.",
        termsOfUse: "Terms of Use",
        aboutus: "About Us",
        community: "Community",
        contactus: "Contact Us",
        shoppingListTitle: "Shopping List",
        shoppingListDescription: "Shopping List page",
        shoppingListKeywords: "Shopping List",
      },
      ariaLabels: {
        promotionalPriceOf: "Promotional Price of {{price}}",
        originalPriceOf: "Original Price of {{price}}",
        alert: "alert",
        itemAddedToList: "{{item}} added to shopping list",
        selectItem: "Select item {{item}}",
        moreOptions: "More Options",
        addItem: "Add item",
        deleteAllItemsFromList: "Delete all items from list",
        addToList: "add {{text}} to list",
        removeFromList: "remove {{text}} from list",
        clearButton: "clearButton",
        active: "active",
        downloadOnTheAppStore: "Download on the app store",
        getOnGooglePlay: "Get on google play",
        listOfItems: "List of {{count}} items",
        clippedCoupons: "Clipped coupons {{count}}",
        redeemedCoupons: "Redeemed coupons {{count}}",
        storeLocation: "store location",
        bannerHome: "{{banner}} home",
        iconFor: "Icon for {{name}}",
        searchBar: "Search for products and site material",
        jobOpeningsAtStore: "Apply at {{name}} store",
        getDirectionsOfStore: "Get Directions for {{name}} store",
        storeAddress: "Store Address",
        applyOnline: "Apply online for job openings in this store",
        rewardButton: "{{buttonText}} for {{rewardTitle}}",
        openDetailsForStore: "Go to {{storeName}} store details",
        openDirectionsForStore:
          "Open Directions for {{storeName}} in new window",
        setStore: "Set {{name}} As My Store",
        storeSelectorType: "Store selector type",
        closeButton: "Close button",
        closeBarcode: "Barcode close button",
        goBack: "Go back",
        breadcrumb: "Breadcrumb",
        closeFilter: "Close filter",
        productCard: "Product card",
        expandMoreLess: "Expand more / less",
        expanded: "Expanded",
        collapsed: "Collapsed",
        closeCouponDetails: "Close coupon details",
        closeSideBar: "Close side bar",
        openSideBar: "Open side bar",
        submit: "Submit",
        back: "Back",
        edit: "Edit",
        editPersonalInfo: "Edit personal information",
        editCashbackSettings: "Edit Cashback Settings",
        cashoutAvailableCashback: "Cashout available cashback",
        useMylocation: "Use my current location",
        primaryButton: "Primary button group",
        typographyTabs: "Typography tabs",
        labApiTab: "Lab API tabs example",
        hide: "Hide",
        show: "Show",
        open: "open",
        inNewTab: "in new tab",
        search: "Search",
        forgotPasswordModal: "Forgot Password Modal",
        changePasswordModal: "Change Password Modal",
        passwordSuccessModal: "Password Success Modal",
        previousPage: "Previos page of {{name}}",
        nextPage: "Next page of {{name}}",
        description: {
          paypalModal: "Modal to add your paypal email",
          cashoutModal: "Modal to show cashout status",
          editCashbackEmailModal: "Modal to show cashback email",
          editPersonalInfoModal: "Edit users personal information data",
          selectStoreModal: "Modal to select a store",
        },
        close: "Close",
      },
      ada: {
        skipToMainContent: "Skip to main content",
        skipToFooter: "Skip to footer",
      },
      shoppingList: {
        title: "Shopping List",
        deleteAllItems: "Delete All Items",
        shareList: "Share List",
        items: "Items",
        completedItems: "Completed Items",
        findProduct: "Find Product",
        uncheckAll: "Uncheck All",
        add: "Add",
        addItemPlaceholder: "+ Add an item, like “milk”, etc.",
        addProduct: '+ Add "{{productName}}"',
        findProducts: 'Find "{{productName}}" products',
        searchText: `Select product for "{{searchTerm}}"`,
        sponsored: "Sponsored",
        maxSearchTextError: "You have reached the {{count}} character limit.",
        editItem: "Edit Item",
        deleteItem: "Delete Item",
        cancel: "Cancel",
        save: "Save",
        selectStoreForPricing: "Select a store for pricing",
        noResultsFound: "No results found for '{{searchText}}'",
        duplicateItemAlert: "Item already added to the shopping list",
        deleteAllItemsConfirmation:
          "Are you sure you want to Delete All Items? All items in you shopping list will be removed!",
        deleteAllItemsNo: "No, Cancel",
        deleteAllItemsYes: "Yes, Delete",
        addItemSuccessAlert:
          'The item "{{productName}}" was added to your Shopping list',
        removeItemSuccessAlert: 'The item "{{productName}}" has been removed',
        shareListSuccessAlert:
          "The Shopping List has been copied to the clipboard",
        maxShoppingItemsAlert:
          "You have reached the Shopping List limit. Please remove some items that you don't need anymore.",
      },
      shoppingListNote: {
        shoppingListNoteHeading: "Access your Shopping Lists from anywhere",
        shoppingListNoteDesc:
          "Log in or create an account today to save your shopping lists for future orders and access them wherever you go.",
        shoppingListNoteLoginBtn: "Log In",
        shoppingListNoteOr: "or",
        shoppingListNoteSignUpBtn: "Sign Up",
      },
      emptyShoppingList: {
        quickCreation: {
          title: "Quick Creation",
          desc: "Simply type in an item to add to Shopping list or click the List icon on product card.",
        },
        neverForget: {
          title: "Never Forget",
          desc: "Check off items as you shop, ensuring you’ve made the most of your shopping trip.",
        },
        discoverCoupons: {
          title: "Discover Coupons",
          desc: "See which products have available coupons to maximize your savings.",
        },
      },
      recipesPage: {
        title: "Title",
        description: "Description",
        ingredients: "Ingredients",
        instructions: "Instructions",
      },
      setPassword: {
        passwordReset: "Password Reset",
        save: "Save",
        setAPassword: "Set a password",
        enterANewPassword: "Please enter a new password for your account",
        newPassword: "New Password",
        passwordMust:
          "Password must be between 8 and 12 characters and must contain at least 1 character from each of the following:",
        checkYourEmailIfCodeNotReceived:
          "Didn’t receive a code? Please verify you signed up with {{email}} and request a new code again.",
      },
    },
  },
}

// for all options read: https://www.i18next.com/overview/configuration-options
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export default i18n
