import { ClipIcon, CloseIcon, DollarInCircle, DoneIcon } from "@/icons"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import ImageIcon from "@mui/icons-material/Image"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import CardMedia from "@mui/material/CardMedia"
import { useQuery } from "@tanstack/react-query"
import generateConfig from "configs/config"
import i18n from "i18n/i18n"
import { useRouter } from "next/router"
import { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { theme as skTheme } from "swiftlykit"
import { convertToLosAngelesTime } from "utils/getLosAngelesTime"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import {
  findBestDecoration,
  findBestDecorationRebates,
  findOfferState,
} from "utils/offers"
import { queryClient } from "utils/queryClient"
import { swiftly } from "../../client-data-bom"
import { AppContext } from "../../contexts/AppContext"
import { useAuth } from "../../contexts/AuthContext"
import {
  clearOpenCouponId,
  setOpenCouponId,
  setOpenPayPalModal,
} from "../../contexts/actions/actions"
import CouponsApi from "../../services/CouponsApi"
import getPixelDensity from "../../utils/getPixelDensity"
import toJson from "../../utils/toJson"
import { TransitionsModal } from "../transitionModal/TransitionModal"
import { setReFetchedCoupons } from "./../../contexts/actions/actions"
import { useClaimCoupon } from "./Coupons"

export const getImageUrl = (
  images: Array<swiftly.types.SwiftlyJsSwiftlyMultiDensityImage>
) => {
  if (!images?.length) return ""
  const density = getPixelDensity()
  const imageInfo = images[0].imageInfo.find(
    (info) => info.density.name === density
  )
  return imageInfo?.url || images[0]?.imageInfo?.[0]?.url || ""
}

const styles = {
  image: {
    height: "100px",
    width: "100px",
  },
  hr: {
    width: "100%",
    height: "1px",
    border: "none",
    borderBottom: "1px dashed lightgrey",
  },
}

const topBarColor: any = {
  new: skTheme?.palette?.couponNew?.main,
  featured: skTheme?.palette?.couponFeatures?.main,
  expires_soon: skTheme?.palette?.couponExpireSoon?.main,
}

const topBarText: any = {
  new: i18n.t("coupons.topBarText.new"),
  featured: i18n.t("coupons.topBarText.featured"),
  expires_soon: i18n.t("coupons.topBarText.expireSoon"),
}

const config = generateConfig()

const CouponsDetailsModal = () => {
  const [showTnC, setShowTnC] = useState<boolean>(false)
  const { isClaiming, claimCoupon } = useClaimCoupon()
  const { isAuthenticated, userId, swiftlyToken } = useAuth()

  const router = useRouter()
  const openCouponId = router.query.openCouponId
  const isRebate = router.query.isRebate
  const isClipped = router.query.isClipped
  const { state, dispatch } = useContext(AppContext)
  const { reFetchedCoupons, isOpenCouponRebate } = state

  const {
    data: fetchedRebate,
    refetch: refetchRebate,
    isLoading: isFetchingRebate,
  } = useQuery(
    ["rebate", "details", state?.openCouponId],
    async () => {
      if (!state.openCouponId || !isOpenCouponRebate) return null

      if (isAuthenticated && userId) {
        return await CouponsApi.fetchRebateDetailsCustomer(
          config.chainId,
          state.openCouponId,
          userId
        )
      }
      return await CouponsApi.fetchRebateDetails(
        config.chainId,
        state.openCouponId
      )
    },
    {
      enabled:
        !!state?.openCouponId &&
        isOpenCouponRebate &&
        config.enableRebatesFeature === "true",
    }
  )

  const {
    state: { categoryList },
  } = useContext(AppContext)

  const {
    data: fetchedCoupon,
    refetch: refetchCoupon,
    isLoading: isFetchingCoupon,
  } = useQuery(
    ["coupon", "details", state?.openCouponId],
    async () =>
      state?.openCouponId && !isOpenCouponRebate
        ? CouponsApi.fetchCouponsById(state.openCouponId, state.isClippedCoupon)
        : null,
    {
      enabled: !!state?.openCouponId && !isOpenCouponRebate,
    }
  )

  useEffect(() => {
    if (openCouponId) {
      const filteredCouponId = Array.isArray(openCouponId)
        ? openCouponId.join("")
        : openCouponId
      dispatch(setOpenCouponId(filteredCouponId, isRebate === "true"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCouponId, isRebate])

  useEffect(() => {
    if (isOpenCouponRebate && config.enableRebatesFeature === "true")
      refetchRebate().catch(console.error)
    else refetchCoupon().catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userId, isOpenCouponRebate])

  useEffect(() => {
    if (!router?.isReady) {
      return
    }

    router.beforePopState(() => {
      setShowTnC(false)
      dispatch(clearOpenCouponId())
      return true
    })

    if (state.openCouponId) {
      const updatedQueries = {
        ...router.query,
        openCouponId: state.openCouponId,
        isRebate: state.isOpenCouponRebate || isRebate === "true",
        isClipped: state.isClippedCoupon || isClipped === "true",
      }
      router
        .replace(
          {
            pathname: router.pathname,
            query: updatedQueries,
          },
          undefined,
          { shallow: true }
        )
        .catch(console.error)
    } else if (router.query.openCouponId) {
      const updatedQueries = { ...router.query }
      updatedQueries.openCouponId = undefined
      updatedQueries.isRebate = undefined
      updatedQueries.isClipped = undefined
      delete updatedQueries.openCouponId
      delete updatedQueries.isRebate
      delete updatedQueries.isClipped
      router
        .replace(
          {
            pathname: router.pathname,
            query: updatedQueries,
          },
          undefined,
          { shallow: true }
        )
        .catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    router?.isReady,
    state.openCouponId,
    state.isOpenCouponRebate,
    dispatch,
    clearOpenCouponId,
    setShowTnC,
  ])

  const closeCouponDetailsModal = () => {
    setShowTnC(false)
    dispatch(clearOpenCouponId())
    gaLogEvent({
      eventName: EventName.navCoupon_closeModal,
      parameters: {
        item_brandName: fetchedCoupon?.brand,
        item_description: fetchedCoupon?.shortDescription,
        item_savings: fetchedCoupon?.valueText,
        imageUrl:
          fetchedCoupon?.images[0].imageInfo[0].url &&
          fetchedCoupon?.images[0].imageInfo[0].url,
        item_message1: fetchedCoupon
          ? findOfferState(fetchedCoupon)
          : undefined,
        item_expirationDate: fetchedCoupon?.expirationDateStr
          ? convertToLosAngelesTime(fetchedCoupon?.expirationDateStr)
              .toLocaleDateString()
              .slice(0, 5)
          : new Date(fetchedCoupon?.expirationDate)
              .toLocaleDateString()
              .replace(/(\b\d\b)/g, "0$1")
              .slice(0, 5),
      },
    })
  }

  // only open when there is valid coupon selected
  const isCouponDetailsModalOpen = !!state.openCouponId

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(767))
  const isDesktop = useMediaQuery(theme.breakpoints.up(1200))

  const { t } = useTranslation()
  const imageUrl = isOpenCouponRebate
    ? fetchedRebate?.imageDetailsUrl
    : getImageUrl(fetchedCoupon?.images || [])

  const clipCoupon = async () => {
    if (isOpenCouponRebate) {
      if (!state.openCouponId) return
      claimCoupon(
        state.openCouponId,
        true,
        () => {
          refetchCoupon().catch(console.error)
          queryClient.invalidateQueries({ queryKey: ["rebate-coupons"] })
          queryClient.invalidateQueries({ queryKey: ["rebate"] })
          closeCouponDetailsModal()
        },
        closeCouponDetailsModal
      )
    } else if (fetchedCoupon && findOfferState(fetchedCoupon) === "active") {
      const reifiedFetchedCoupon = toJson(
        fetchedCoupon.reify(swiftly.offers.JsSwiftlyOffersReificationContext)
      )
      claimCoupon(
        reifiedFetchedCoupon.id,
        false,
        (cp) => {
          if (cp?.id) {
            dispatch(
              setReFetchedCoupons([
                ...reFetchedCoupons.filter(
                  (coupon: any) => coupon.id !== cp?.id
                ),
                cp,
              ])
            )
          } else {
            const coupon = {
              ...reifiedFetchedCoupon,
              id:
                reifiedFetchedCoupon.id.split("-").length > 2
                  ? reifiedFetchedCoupon.id.split("-").slice(0, -1).join("-")
                  : reifiedFetchedCoupon.id,
              shopperOffer: {
                ...reifiedFetchedCoupon.shopperOffer,
                claimed: true,
              },
            } as swiftly.offers.SwiftlyJsSwiftlyOffer
            dispatch(
              setReFetchedCoupons([
                ...reFetchedCoupons.filter(
                  (coupon: any) => coupon.id !== reifiedFetchedCoupon.id
                ),
                coupon,
              ])
            )
          }
          refetchCoupon().catch(console.error)
          // Refetch all types of coupons
          queryClient.invalidateQueries({ queryKey: ["rebate-coupons"] })
          queryClient.invalidateQueries({ queryKey: ["coupons"] })
          queryClient.invalidateQueries({ queryKey: ["claimed-coupons"] })
          closeCouponDetailsModal()
        },
        closeCouponDetailsModal
      )
    }
  }

  const handleTermsAndConditions = (e: any) => {
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      setShowTnC(!showTnC)
      const categoryName: any = categoryList?.find(
        (item: any) => item.id === state.selectedCouponCategoryId
      )
      gaLogEvent({
        eventName: EventName.coupon_termsAndCond,
        parameters: {
          item_nameCategory: categoryName && categoryName?.name,
          item_nameCategorySub: "",
          item_brandName: fetchedCoupon?.brand,
          item_description: fetchedCoupon?.shortDescription,
          item_savings: fetchedCoupon?.valueText,
          imageUrl:
            fetchedCoupon?.images[0].imageInfo[0].url &&
            fetchedCoupon?.images[0].imageInfo[0].url,
          item_location: "Modal",
          item_message1: fetchedCoupon
            ? findOfferState(fetchedCoupon)
            : undefined,
          item_expirationDate: fetchedCoupon?.expirationDateStr
            ? convertToLosAngelesTime(fetchedCoupon?.expirationDateStr)
                .toLocaleDateString()
                .slice(0, 5)
            : new Date(fetchedCoupon?.expirationDate)
                .toLocaleDateString()
                .replace(/(\b\d\b)/g, "0$1")
                .slice(0, 5),
        },
      })
    }
  }

  const couponState = useMemo(() => {
    if (isOpenCouponRebate && fetchedRebate) {
      if (fetchedRebate.status === "Available") return "active"
      if (fetchedRebate.status === "Clipped") return "clipped"
      return "expired"
    }

    if (fetchedCoupon) {
      return findOfferState(fetchedCoupon)
    }
  }, [fetchedCoupon, fetchedRebate, isOpenCouponRebate])

  const couponDetails = useMemo(() => {
    if (
      isOpenCouponRebate &&
      !isFetchingRebate &&
      config.enableRebatesFeature === "true"
    ) {
      return (
        fetchedRebate && {
          valueText: fetchedRebate.valueDisplay,
          brand: fetchedRebate.brand,
          expirationDate: convertToLosAngelesTime(
            fetchedRebate.expirationDate
          ).toLocaleDateString(),
          shortDescription: fetchedRebate.shortDescription,
          terms: fetchedRebate?.termsAndConditions,
          topBar: findBestDecorationRebates(fetchedRebate),
        }
      )
    } else if (!isOpenCouponRebate && !isFetchingCoupon) {
      return (
        fetchedCoupon && {
          valueText: fetchedCoupon.valueText,
          brand: fetchedCoupon.brand,
          expirationDate: convertToLosAngelesTime(
            fetchedCoupon.expirationDate
          ).toLocaleDateString(),
          shortDescription: fetchedCoupon.shortDescription,
          terms: fetchedCoupon.terms,
          topBar: findBestDecoration(fetchedCoupon),
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedCoupon,
    fetchedRebate,
    isOpenCouponRebate,
    isFetchingCoupon,
    isFetchingRebate,
  ])

  return (
    <TransitionsModal
      ariaLabelledBy="couponDetails"
      ariaDescribedBy="couponDescription"
      open={isCouponDetailsModalOpen}
      onClose={closeCouponDetailsModal}
      sx={{
        width: isMobile ? "90%" : "460px",
        height: "auto",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "0.8rem",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {isCouponDetailsModalOpen ? (
        <Stack flex={1} data-cy="coupon-details-modal">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              p: isMobile ? 2 : isDesktop ? 3 : 3,
              paddingBottom: 0,
              pr: isMobile ? 1 : 2,
              pt: isMobile ? 1 : 2,
            }}
          >
            <Typography variant="h3" fontWeight="bold" id="couponDetails">
              {isOpenCouponRebate
                ? t("coupons.rebateDetails")
                : t("coupons.details")}
            </Typography>
            <IconButton
              onClick={closeCouponDetailsModal}
              aria-label={t("ariaLabels.closeCouponDetails")}
            >
              <CloseIcon color={theme.palette.ctaPrimary?.main} />
            </IconButton>
          </Stack>

          {couponDetails ? (
            <Stack flex={1} sx={{ p: isMobile ? 2 : isDesktop ? 3 : 3 }}>
              {couponDetails?.topBar && couponDetails?.topBar !== "empty" && (
                <Box>
                  <Typography
                    sx={{
                      backgroundColor: topBarColor[couponDetails.topBar],
                      p: "2px 1rem",
                      mb: 1,
                      width: "fit-content",
                    }}
                  >
                    {topBarText[couponDetails.topBar]}
                  </Typography>
                </Box>
              )}
              {imageUrl ? (
                <CardMedia
                  component="img"
                  image={imageUrl}
                  alt={couponDetails?.valueText}
                  sx={styles.image}
                  aria-hidden="true"
                  role="presentation"
                />
              ) : (
                <CardMedia sx={styles.image}>
                  <ImageIcon fontSize="large" />
                </CardMedia>
              )}
              <Typography
                variant="h3"
                sx={{
                  mt: 2,
                  mb: 1,
                  color: theme.palette.ctaPrimary.main,
                  fontWeight: 600,
                }}
              >
                {couponDetails?.valueText}
              </Typography>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {couponDetails?.brand}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.text.secondary,
                }}
              >
                {t("coupons.expiry", {
                  str: couponDetails?.expirationDate,
                })}
              </Typography>
              <Divider sx={{ borderStyle: "dashed", my: 2 }} />
              {isOpenCouponRebate && (
                <Typography variant="h6" mb={1}>
                  {t("coupons.rebateDescriptionTitle")}
                </Typography>
              )}
              <Typography variant="body1" id="couponDescription">
                {couponDetails?.shortDescription}
              </Typography>
              <Divider sx={{ borderStyle: "dashed", my: 2 }} />
              {isOpenCouponRebate && (
                <Box mb={2}>
                  <Typography variant="h6" mb={1}>
                    {t("coupons.rebateTermsTitle")}
                  </Typography>

                  <Typography
                    variant="caption"
                    color={theme.palette.textMuted.dark}
                  >
                    {t("coupons.rebateTermsDescription")}
                  </Typography>
                </Box>
              )}

              {isOpenCouponRebate && (
                <LoadingButton
                  loading={isClaiming}
                  sx={{
                    backgroundColor: theme.palette.lightPrimary?.main,
                    py: 2,
                  }}
                  onClick={() => {
                    closeCouponDetailsModal()
                    dispatch(setOpenPayPalModal())
                  }}
                >
                  <DollarInCircle
                    height="2rem"
                    width="2rem"
                    fill={theme.palette.ctaPrimary?.main}
                  />
                  <Typography
                    color={theme.palette.ctaPrimary?.main}
                    sx={{ mx: 3 }}
                  >
                    {t("coupons.paypalButtonText")}
                  </Typography>
                </LoadingButton>
              )}
              {couponDetails?.terms && (
                <>
                  <Divider sx={{ borderStyle: "dashed", my: 2 }} />
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      cursor: "pointer",
                    }}
                    tabIndex={0}
                    onClick={handleTermsAndConditions}
                    onKeyDown={handleTermsAndConditions}
                  >
                    <Typography
                      variant="label"
                      color={theme.palette.ctaPrimary?.main}
                    >
                      {t("coupons.terms")}
                    </Typography>
                    {showTnC ? (
                      <ExpandLess sx={{ color: "ctaPrimary.main" }} />
                    ) : (
                      <ExpandMore sx={{ color: "ctaPrimary.main" }} />
                    )}
                  </Stack>
                  <Collapse
                    in={showTnC}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      marginTop: 1,
                      ".MuiCollapse-wrapperInner": {
                        height:
                          couponDetails?.terms.length > 255 ? "110px" : "auto",
                        overflowY:
                          couponDetails?.terms.length > 255 ? "auto" : "none",
                      },
                    }}
                  >
                    <Typography
                      variant="caption"
                      color={theme.palette.textMuted.dark}
                    >
                      {couponDetails?.terms}
                    </Typography>
                  </Collapse>
                  <Divider sx={{ borderStyle: "dashed", my: 2 }} />
                </>
              )}
              <LoadingButton
                loading={isClaiming}
                disableElevation
                data-cy="clipCoupon"
                sx={{
                  backgroundColor: theme.palette.ctaPrimary?.main,
                  "&.Mui-disabled": {
                    border: "unset",
                    backgroundColor: theme.palette.ctaPrimary?.main,
                    py: "12px",
                  },
                  justifyContent: "center",
                  fontSize: 15,
                  px: 2,
                  py: 1,
                  borderStyle: "solid",
                }}
                fullWidth
                variant="contained"
                color="ctaPrimary"
                onClick={() => {
                  clipCoupon()
                  const categoryName: any = categoryList?.find(
                    (item: any) => item.id === state.selectedCouponCategoryId
                  )
                  gaLogEvent({
                    eventName: EventName.coupon_clip,
                    parameters: {
                      item_nameCategory: categoryName?.name,
                      item_nameCategorySub: "",
                      item_brandName: fetchedCoupon?.brand,
                      item_description: fetchedCoupon?.shortDescription,
                      imageUrl:
                        fetchedCoupon?.images[0].imageInfo[0].url &&
                        fetchedCoupon?.images[0].imageInfo[0].url,
                      item_savings: fetchedCoupon?.valueText,
                      item_location: "Modal",
                      item_message1: fetchedCoupon
                        ? findOfferState(fetchedCoupon)
                        : undefined,
                      item_expirationDate: fetchedCoupon?.expirationDateStr
                        ? convertToLosAngelesTime(
                            fetchedCoupon?.expirationDateStr
                          )
                            .toLocaleDateString()
                            .slice(0, 5)
                        : new Date(fetchedCoupon?.expirationDate)
                            .toLocaleDateString()
                            .replace(/(\b\d\b)/g, "0$1")
                            .slice(0, 5),
                      User_Coupon_Clip: "true",
                    },
                  })
                }}
                disabled={couponState !== "active"}
              >
                {couponState === "active" ? (
                  <ClipIcon color={theme.palette.common.white} />
                ) : (
                  <DoneIcon color={theme.palette.common.white} />
                )}
                <Typography color={theme.palette.common.white} ml={1}>
                  {couponState === "clipped"
                    ? t("coupons.clippedText")
                    : couponState === "redeemed"
                    ? t("coupons.redeemedText")
                    : couponState === "expired"
                    ? t("coupons.expiredText")
                    : t("coupons.clip")}
                </Typography>
              </LoadingButton>
            </Stack>
          ) : isFetchingCoupon || isFetchingRebate ? (
            <Stack
              flex={1}
              justifyContent="center"
              alignItems="center"
              sx={{ p: 2, minHeight: 200 }}
            >
              <CircularProgress color="ctaPrimary" />
            </Stack>
          ) : (
            <Stack
              flex={1}
              justifyContent="center"
              alignItems="center"
              sx={{ p: 2, minHeight: 200 }}
            >
              {`cannot find coupon details: ${JSON.stringify(fetchedCoupon)}`}
            </Stack>
          )}
        </Stack>
      ) : null}
    </TransitionsModal>
  )
}

export default CouponsDetailsModal
